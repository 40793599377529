<template>
  <div class="bg-black-0 rounded py-3 px-16 px-sm-32 mx-0">
    <h2>Form Tambah Daftar Rumah Sakit</h2>
    <hr class="w-100">
    <b-row>
      <b-col
        cols="6"
        class="mb-3"
      >
        <b-form-group
          label="Nama Rumah Sakit"
          label-for="name-rs"
        >
          <b-form-input
            id="name-rs"
            v-model="formData.hospitalName"
            placeholder="Masukkan Nama Rumah Sakit"
            type="text"
          />
        </b-form-group>
        <b-form-group
          label="Kontak Rumah Sakit"
          label-for="contact-rs"
        >
          <b-form-input
            id="contact-rs"
            v-model="formData.contact"
            placeholder="Masukkan Kontak Rumah Sakit"
            type="text"
          />
        </b-form-group>
      </b-col>

      <b-form-group
        label="Email Rumah Sakit"
        label-for="email-address-rs"
      >
        <b-form-input
          id="email-address-rs"
          v-model="formData.email"
          placeholder="Masukkan Email Rumah Sakit"
          type="email"
        />
      </b-form-group>

      <b-col
        cols="6"
        class="mb-3"
      >
        <b-form-group
          label="Alamat Rumah Sakit"
          label-for="address-rs"
        >
          <google-maps
            v-model="formData.address"
            @address-extracted="handlePlaceSelected"
          />
        </b-form-group>
      </b-col>

      <b-col class="d-flex justify-content-end mb-3">
        <b-button
          variant="danger"
          class="mr-3"
        >Batal</b-button>

        <b-button
          :disabled="!isFormValid"
          variant="primary"
          class="mr-3"
          @click="postHospitals"
        >Simpan</b-button>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import {
  BButton, BInputGroup, BInputGroupPrepend, BFormInput, BSpinner, BForm, BFormGroup,
} from 'bootstrap-vue'
import tableDataMixins from '@/mixins/tableDataMixins'
import GoogleMaps from '@/components/maps/GoogleMaps.vue'
import manageHospitalAPI from '../../../../api/managehospital/manageHospitalAPI'

export default {
  name: 'Hospitals',
  components: {
    BButton, BInputGroup, BInputGroupPrepend, BFormInput, BSpinner, BForm, BFormGroup, GoogleMaps,
  },
  mixins: [tableDataMixins],
  data() {
    return {
      isBusy: false,
      formData: {
        hospitalName: '',
        address: '',
        contact: '',
        email: '',
      },
    }
  },
  computed: {
    isFormValid() {
      return (
        this.formData.hospitalName
          && this.formData.address
          && this.formData.email
          && this.formData.contact
      )
    },
  },
  created() {
    this.table.items = this.items
  },
  methods: {
    async postHospitals() {
      const formData = new FormData()

      formData.append('name', this.formData.hospitalName)
      formData.append('phone', this.formData.contact)
      formData.append('address', this.formData.address)
      formData.append('email', this.formData.email)

      await manageHospitalAPI.addRSDropping(formData).then(response => {
        this.$router.push({ name: 'list-master-data-rs' })

        this.$bvToast.toast('Data berhasil disimpan', {
          title: 'Success',
          variant: 'success',
          solid: true,
        })
      }).catch(e => console.error(e))
    },
    handlePlaceSelected(place) {
      this.formData.address = place.formattedAddress
    },
  },
}
</script>
